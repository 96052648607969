#Support {
  display: flex;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.SupportUsContainer {
  text-align: left;
}

#social-icon-box {
  justify-content: left;
}

.SupportSectionContainer {
  margin-bottom: 50px;
}

.social-icon {
  width: 40px;
}

@media (max-width: 900px) {
  #Support {
    padding-left: 5%;
    padding-right: 5%;
  }

  .SupportUsContainer {
    text-align: center;
  }

  #social-icon-box {
    justify-content: center;
  }
}

a:link {
  color: inherit;
}
