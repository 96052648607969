#toolbar {
  padding: 0px;
}

@media (max-width: 600px) {
  .navbar-buttons {
    display: none;
  }
}

@media (min-width: 600px) {
  #menu-button {
    display: none;
  }
}
