#About {
  padding-top: 5%;
  padding-bottom: 5%;
  align-self: center;
  text-align: center;
}

.AboutUsDesc {
  padding-left: 0px;
}

.photo-wrapper {
  margin-top: 15px;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./../../fonts/JosefinSans-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Rastano';
  src: url('./../../fonts/Rastano.ttf') format('truetype');
}

.shadow-rectangle {
  width: 460px;
  height: auto;
  transform: rotate(4.67deg);
  border: 8px solid transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 5%;
  text-align: center;
}

.photo {
  position: relative;
  top: 10px;
  width: 95%;
  height: 95%;
  object-fit: cover;
  border-radius: 8px;
}

@media only screen and (max-width: 1000px) {
  #About {
    padding-left: 5%;
    padding-right: 5%;
  }
  .shadow-rectangle {
    width: 95%;
  }

  .photo {
    width: 90%;
    height: 90%;
  }
}
