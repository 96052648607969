@font-face {
  font-family: 'Josefin Sans';
  src:
    local('JosefinSans'),
    url('./../../fonts/JosefinSans-VariableFont_wght.ttf') format('truetype');
}

.the-team {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Josefin Sans';
  font-weight: 500;
  font-size: xx-large;
  color: white;
  text-align: center;
  padding-top: 1%;
}

.slider {
  white-space: nowrap;
  overflow: hidden;
  padding: 20px 0;
  cursor: pointer;
}

.slider:hover .imgs-slide {
  animation-play-state: paused;
}

.dialog-image {
  width: 200px; /* Fixed width */
  height: 200px; /* Fixed height */
  border-radius: 50%; /* Make the dialog image circular */
  object-fit: cover; /* Ensure the image covers the area without stretching */
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.imgs-slide {
  display: inline-block;
  animation: 40s slide infinite linear;
  perspective: 100px;
}

.imgs-slide img {
  height: 80px;
  width: 80px;
  max-width: 80px;
  margin: 0 24px;
  transition: transform 0.5s;
  border-radius: 20px;
  object-fit: cover; /* Ensure the image covers the area without stretching */
}

.imgs-slide img:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 700px) {
  .the-team {
    font-size: large;
  }
  .imgs-slide img {
    height: 60px; /* Smaller images for mobile */
    width: 60px; /* Smaller images for mobile */
  }

  .dialog-image {
    width: 150px; /* Smaller dialog image for mobile */
    height: 150px; /* Smaller dialog image for mobile */
  }
}
