html {
  scroll-behavior: smooth;
}

#App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  font-family: sans-serif;
  color: white;
}

.App-Component {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
}

.Hero-Background {
  background-color: #ebe9fa;
  border-top: solid;
  border-top-color: #a6a8ce;
  min-height: 500px;
}

.Values-Team-Background {
  background-image: linear-gradient(#9292c9, #e0cbe8, #9f93c1);
  background-size: 100vw !important;
}

@media screen and (max-width: 1024px) {
  .Nav-Component {
    padding-left: 5%;
    padding-right: 5%;
  }
}
